import './Card.scss';
import { memo } from 'react';

const Card = () => {
    return(
        <div className='card-project'>
        </div>
    )
}

export default memo(Card);